<template>
  <div class="channelSetting">
    <div class="searchBox">
      <search-input
        class="searchInput"
        ref="searchInput"
        :placeholder="$t('lang.searchChannel')"
        :callback="searchChannel"
        :value="search_key"></search-input>
      <div class="type">
        {{$t(`lang.autoType`)}}：
        <el-radio-group
          v-model="manual"
          @change="getChannelList">
          <el-radio :label="2">{{$t(`lang.all`)}}</el-radio>
          <el-radio :label="0">{{$t(`lang.automatic`)}}</el-radio>
          <el-radio :label="1">{{$t(`lang.manual`)}}</el-radio>
        </el-radio-group>
      </div>
      <div class="type">
        {{$t(`lang.occurType`)}}：
        <el-radio-group
          v-model="idle"
          @change="getChannelList">
          <el-radio :label="2">{{$t(`lang.all`)}}</el-radio>
          <el-radio :label="0">{{$t(`lang.occupy`)}}</el-radio>
          <el-radio :label="1">{{$t(`lang.idle`)}}</el-radio>
        </el-radio-group>
      </div>
      <div
        class="btnBox"
        v-show="checkedFlag">
        <el-button
          size="small"
          type="primary"
          @click="setManual(0)">{{$t(`lang.setAutoBtn`)}}</el-button>
        <el-button
          size="small"
          class="black"
          @click="setManual(1)">{{$t(`lang.setManualBtn`)}}</el-button>
      </div>
      <!-- 分页组件 -->
      <Pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :pageSizes="[8, 20, 30, 50]"
        :pageSize="pageSize"
        :total="total"
        layout="prev, pageF, next, pageL, nowSize, jumper" />
    </div>
    <el-table
      v-loading="loading"
      element-loading-background="transparent"
      class="listTable"
      :data="tableData"
      @selection-change="handleSelectionChange">
      <el-table-column
        v-if="tableData && tableData.length > 0"
        type="selection"
        width="55"></el-table-column>
      <el-table-column :label="$t('lang.channelName')">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('lang.channelType')">
        <template slot-scope="scope">
          <span v-if="scope.row.manual == 0">{{$t(`lang.automatic`)}}</span>
          <span v-else>{{$t(`lang.manual`)}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('lang.occupyStatus')">
        <template slot-scope="scope">
          <span v-if="scope.row.occupyStatus == 0">{{$t(`lang.occupy`)}}</span>
          <span v-else-if='scope.row.occupyStatus == 1'>{{$t(`lang.idle`)}}</span>
          <span v-else>{{$t(`lang.unUseable`)}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('lang.groupName')">
        <template slot-scope="scope">
          <span>{{ scope.row.group }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('lang.channelSource')">
        <template slot-scope="scope">
          <span>{{ scope.row.livePeerId }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('lang.deviceStatus')">
        <template slot-scope="scope">
          <span v-if="scope.row.status == '0'">{{ $t(`lang.offline`) }}</span>
          <span v-else-if="scope.row.status == '1'">{{ $t(`lang.available`) }}</span>
          <span v-else-if="scope.row.status == '2'">{{ $t(`lang.live`) }}</span>
          <span v-else>{{ $t(`lang.noSource`) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import Outil from '@/assets/js/utils.js'
import Pagination from '@/components/Pagination'
import SearchInput from '@/components/Search'
import { getChannelPage, setManual } from '@/assets/api/sourceControl'
export default {
  name: 'settingChannel',
  data () {
    return {
      search_key: '',
      idle: 2,
      manual: 2,
      currentPage: 1,
      pageSize: 10,
      total: 20,
      loading: false,
      tableData: [],
      checkedFlag: false,
      checkedData: []
    }
  },
  created () {
    this.getChannelList()
  },
  methods: {
    // 手动及自动设置
    setManual (num) {
      let peerIds = []
      this.checkedData.forEach(v => {
        peerIds.push(v.peerId)
      })
      let params = {
        peerIds: peerIds,
        manual: num
      }
      setManual(params).then(res => {
        if (res.data.errorCode === '0x0') {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.getChannelList()
        } else {
          Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
        }
      }).catch(() => {})
    },
    searchChannel (val) {
      this.search_key = val
      this.currentPage = 1
      this.getChannelList()
    },
    getChannelList () {
      this.loading = true
      let params = {
        condition: this.search_key,
        manual: this.manual == '2' ? null : this.manual,
        idle: this.idle == '2' ? null : this.idle,
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }
      getChannelPage(params).then(res => {
        this.loading = false
        if (res.data.errorCode == '0x0') {
          let data = res.data.result
          this.total = data.total
          this.tableData = data.list
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelectionChange (val) {
      this.checkedData = val
      this.checkedFlag = val.length > 0
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.currentPage = 1
      this.getChannelList()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getChannelList()
    }
  },
  components: {
    SearchInput,
    Pagination
  }
}
</script>
<style lang="less" scoped>
.channelSetting {
  text-align: left;
  .searchBox {
    .searchInput {
      display: inline-block;
      width: 300px;
      height: 32px;
      margin-right: 20px;
      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        border: 1px solid #33ab4f;
      }
    }
    .type {
      display: inline-block;
      margin: 0 20px;
      ::v-deep .el-radio {
        margin-right: 10px;
      }
      .el-radio-group {
        margin-top: 5px;
      }
    }
    .btnBox {
      display: inline-block;
    }
  }
  ::v-deep .el-table::before {
    height: 0;
  }
}
</style>
